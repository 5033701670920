export default ((string, regExp, wrap, next) => {
  const result = [];
  let lastIndex = 0;
  let found = regExp.exec(string);
  while (found) {
    result.push(next(string.slice(lastIndex, found.index)));
    result.push(wrap(found));
    lastIndex = regExp.lastIndex;
    found = regExp.exec(string);
  }
  result.push(next(string.slice(lastIndex)));
  return result;
});